<template>
  <div style="width:100%;">
    <el-upload ref="upload" :on-change="onChange" action="/imageapi/common/upload" list-type="picture-card" :on-exceed="handleExceed" :on-preview="handlePictureCardPreview" :file-list="value" :on-success="success" multiple :limit="limit" :on-remove="handleRemove" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg">
      <i class="el-icon-plus"></i>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过10MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  mounted () { },
  methods: {
    handleRemove (file, fileList) {
      this.$emit('input', fileList)
    },
    onChange (file) {
      let isLt = file.size / 1024 < 10240 // 判断是否小于10MB
      if (!isLt) {
        this.$message.warning('图片大小不能超过10MB! 请重新上传')
        this.$refs.upload.clearFiles()
      } else {
        return true
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    success (response, file, fileList) {
      this.$emit('input', fileList)
      console.log("form success", fileList)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
