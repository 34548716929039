/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-20 20:09:04
 * @Module: 选择城市
 */
 <template>
  <el-cascader :props="props" placeholder="小号可能在哪些城市直播" @change="change" style="width:100%;"></el-cascader>
</template>
 <script>
import axios from "axios"
export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  components: {},
  data () {
    return {
      props: {
        lazy: true,
        multiple: true,
        lazyLoad: (node, resolve) => {
          console.log(node)
          const { level, data } = node;
          this.$api.getCitys({ pid: level > 0 ? data.id : 0 }).then(({ result }) => {
            resolve(result.map(item => {
              return {
                value: item.name,
                id: item.id,
                label: item.name,
                leaf: item.level >= 2
              }
            }));
          })
        }
      }
    };
  },
  mounted () {
  },
  methods: {
    change (res) {
      console.log(res)
      this.$emit('input', res)
    },

  },
};
 </script>
 <style lang='scss' scoped>
</style>