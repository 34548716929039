/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-20 21:36:56
 * @Module: 平台
 */
 <template>
  <el-select :value="value" :multiple="multiple" :placeholder="placeholder" clearable @change="change" style="width:100%;">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>
 <script>
import platformList from "./platformList.js";

export default {
  props: {
    value: {
      type: [Array, String],
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: '小号可能在哪些平台直播（基础1个平台，追加一个平台1个豆）'
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  //1=抖音,2=快手,3=YY,4=虎牙,5=微信视频号直播,6=网易CC,7=网易look,8=斗鱼直播,9=映客直播,10=花椒直播,11=陌陌直播,12=探探直播,13=哔哩哔哩直播,14=繁星
  data () {
    return {
      options: platformList
    };
  },
  mounted () { },
  methods: {
    change (res) {
      this.$emit('input', res)
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>